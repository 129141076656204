import React, { useEffect, useState } from 'react';

function Counter({ end, duration, suffix, prefix, className, start, formatNumber }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!start) return;

    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / (duration * 1000), 1);
      setCount(progress * end);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, [start, end, duration]);

  return (
    <span className={className}>
      {prefix}{formatNumber ? formatNumber(count.toFixed(2)) : count.toFixed(2)}{suffix}
    </span>
  );
}

export default Counter;