import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import {UserProvider} from './context/UserContext';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Académie from './pages/Académie';
import Actualités from './pages/Actualités';
import APropos from './pages/APropos';
import SearchResults from './components/SearchResults';
import SearchDropdown from './components/SearchDropdown';
import SearchDropdownBourse from './components/SearchDropdownBourse';
import SearchDropdownCrypto from './components/SearchDropdownCrypto';
import ArticlePage from './Articles/ArticlePage';
import ScrollToTop from './components/ScrollToTop'; // Importez le composant ScrollToTop
import Crypto from './pages/Crypto';

function App() {
  const [showNavbar, setShowNavbar] = useState(true);

  const handleHideNavbar = () => {
    setShowNavbar(false);
  };

  return (
    <UserProvider>
      <Router>
        <ScrollToTop /> {/* Ajoutez le composant ScrollToTop */}
        {showNavbar && <Navbar />}
        <MainContent handleHideNavbar={handleHideNavbar} />
      </Router>
    </UserProvider>
  );
}

const MainContent = ({ handleHideNavbar }) => {
  const location = useLocation();
  const isArticlePage = location.pathname.startsWith('/articles');

  return (
    <div className={`main-content ${isArticlePage ? 'no-padding' : ''}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/académie" element={<Académie />} />
        <Route path="/actualités" element={<Actualités />} />
        <Route path="/apropos" element={<APropos />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/searchDropdown" element={<SearchDropdown />} />
        <Route path="/searchDropdownBourse" element={<SearchDropdownBourse />} />
        <Route path="/searchDropdownCrypto" element={<SearchDropdownCrypto />} />
        <Route path="/articles/:slug" element={<ArticlePage />} />
        <Route path="/crypto" element={<Crypto />} />
      </Routes>
    </div>
  );
};

export default App;