import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { articleData } from '../Articles/ArticlePage'; // Importez les articles
import '../pages/Actualités.css'; // Importez le fichier CSS

// Convertir les données des articles en un tableau et trier par date
const articles = Object.keys(articleData).map(key => ({
  id: key,
  title: articleData[key].title,
  date: new Date(articleData[key].date), // Convertir la date en objet Date
  author: articleData[key].author,
  imageUrl: articleData[key].imageUrl1, // Assurez-vous d'utiliser la bonne clé pour l'image
  tag: articleData[key].tag, // Ajouter le tag
  link: `/articles/${key}`
})).sort((a, b) => b.date - a.date); // Trier les articles par date (du plus récent au plus ancien)

const Actualités = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.state && location.state.scrollToId) {
      const element = document.getElementById(location.state.scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const elements = document.querySelectorAll('.guideCard, .news-card, .ressources-card, .ActuSecondaryButton');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    elements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      elements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);

  const handleRedirect = () => {
    navigate('/académie');
    window.scrollTo(0, 0);
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cryptoArticles = articles.filter(article => article.tag === 'crypto').slice(0, 3);
  const bourseArticles = articles.filter(article => article.tag === 'bourse').slice(0, 3);

  console.log('Crypto Articles:', cryptoArticles);
  console.log('Bourse Articles:', bourseArticles);

  return (
    <div className="ActuBackground">
      <div className="ActuContainer">
        {/* En-tête */}
        <header className="header">
          <h1 className="title">Maîtrisez l'Investissement Avec Edufin</h1>
          <p className="subtitle">
            Découvrez nos guides et actualités pour maîtriser les investissements en cryptomonnaie et en bourse
          </p>
          <button className="primaryButton" onClick={handleRedirect}>
            Commencez dès maintenant
          </button>
        </header>

        {/* Actualités Crypto */}
        <section id="crypto" className="latestNewsSection">
          <h2 className="sectionTitle">Dernières Actualités Cryptomonnaies</h2>
          <div className="newsGrid">
            {cryptoArticles.length > 0 ? (
              cryptoArticles.map((article, index) => (
                <Link
                  key={index}
                  to={article.link}
                  className={`guideCard ${hoveredCard === index ? 'guideCardHover' : ''}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img src={article.imageUrl} alt={article.title} className="image" />
                  <p className="newsMeta">{article.date.toLocaleDateString()} | {article.author}</p>
                  <h3 className="newsTitle">{article.title}</h3>
                </Link>
              ))
            ) : (
              <p>Aucun article disponible.</p>
            )}
          </div>
          <div className="ActuButtonContainer">
            <Link to="/searchDropdownCrypto">
              <button className="ActuSecondaryButton">Voir plus d'articles</button>
            </Link>
          </div>
        </section>

        {/* Actualités Bourse */}
        <section id="bourse" className="latestNewsSection">
          <h2 className="sectionTitle">Dernières Actualités Boursières</h2>
          <div className="newsGrid">
            {bourseArticles.length > 0 ? (
              bourseArticles.map((article, index) => (
                <Link
                  key={index}
                  to={article.link}
                  className={`guideCard ${hoveredCard === index ? 'guideCardHover' : ''}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img src={article.imageUrl} alt={article.title} className="image" />
                  <p className="newsMeta">{article.date.toLocaleDateString()} | {article.author}</p>
                  <h3 className="newsTitle">{article.title}</h3>
                </Link>
              ))
            ) : (
              <p>Aucun article disponible.</p>
            )}
          </div>
          <div className="ActuButtonContainer">
            <Link to="/searchDropdownBourse">
              <button className="ActuSecondaryButton">Voir plus d'articles</button>
            </Link>
          </div>
        </section>

        {/* Section Guide */}
        <section id="guide" className="guidesSection">
          <h2 className="sectionTitle">Guides pour débutants</h2>
          <div className="guidesGrid">
            {/* Guide 1 */}
            <a
              href="https://edufin.podia.com/a9ac8255-3567-42dd-b0cb-26800252d0ca" target="_blank" rel="noopener noreferrer"
              className={`guideCard ${hoveredCard === 6 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(6)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/dollar-crypto.webp" alt="Guide 1" className="image" />
              <h3 className="guideTitle">Guide cryptomonnaies</h3>
            </a>
            {/* Guide 2 */}
            <a
              href="https://edufin.podia.com/guide-complet-introduction-a-la-bourse-starter-pack" target="_blank" rel="noopener noreferrer"
              className={`guideCard ${hoveredCard === 7 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(7)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/BourseImage.jpg" alt="Guide 2" className="image" />
              <h3 className="guideTitle">Guide bourse</h3>
            </a>
          </div>
        </section>
        {/* Section Newsletters */}
        <section id="newsletter" className="newsletterSection">
          <header className="header2">
            <h2 className="newsletterTitle">Abonnez-vous à notre Newsletter !</h2>
            <p className="subtitle2">
              Recevez les dernières actualités et analyses des marchés financiers directement dans votre boîte mail.
            </p>
            <a href="https://ca841cc5.sibforms.com/serve/MUIFAPuSy5Nx0Vchq3EVduGDudsQrDBzu6XkbzgXC8GgaT0W65N8nmNxWDgJvBSFf-DLKmSOmfmabcYxv7mMfkALgE7p6iFoa067WChDx3WqjXln2E3854fisQ0Yy6LT6O5j-NVBlFF-nDUVGRWrMHSLD6FZzLigJdKQlpgXh0Dqn1f2OVhFEQw9RTjtmf-bWePuPgLlxKqJ1z8x" target="_blank" rel="noopener noreferrer">
              <button className="primaryButton">S'abonner</button>
            </a>
          </header>
        </section>
      </div>
    </div>
  );
}

export default Actualités;