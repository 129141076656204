import './APropos.css'; 
import { useEffect, useState } from 'react';
import HugoBoulet from '../assets/Hugo_Boulet-removebg-preview.png';
import AdrienMoncet from '../assets/Adrien_Moncet-removebg-preview.png';
import BaptisteFerahian from '../assets/Baptise_Ferahian-removebg-preview.png';
import nosValeur1 from '../assets/nosValeur1.png';
import nosValeur2 from '../assets/nosValeur2.png';
import nosValeur3 from '../assets/nosValeur3.png';
import nosValeur4 from '../assets/nosValeur4.png';

function APropos() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        setHasScrolled(true);
      }

      if (hasScrolled && imagesLoaded) {
        const items = document.querySelectorAll('.text-container');
        items.forEach((item, index) => {
          const rect = item.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight * 1.1) {
            setTimeout(() => {
              item.classList.add('visible');
            }, index * 200); // Déclenche l'animation avec un décalage de 200ms entre chaque élément
          }
        });

        const advantageCards = document.querySelectorAll('.founder');
        advantageCards.forEach((card, index) => {
          const rect = card.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight * 1.1) {
            setTimeout(() => {
              card.classList.add('visible');
            }, index * 200); // Déclenche l'animation avec un décalage de 200ms entre chaque carte
          }
        });

        const valueImagesSection = document.querySelector('.value-images-section');
        if (valueImagesSection) {
          const rect = valueImagesSection.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight * 1.1) {
            valueImagesSection.classList.add('visible');
          }
        }
      }
    };

    const checkImagesLoaded = () => {
      const images = document.querySelectorAll('img');
      const totalImages = images.length;
      let loadedImages = 0;

      images.forEach(image => {
        if (image.complete) {
          loadedImages++;
        } else {
          image.addEventListener('load', () => {
            loadedImages++;
            if (loadedImages === totalImages) {
              setImagesLoaded(true);
            }
          });
        }
      });

      if (loadedImages === totalImages) {
        setImagesLoaded(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    checkImagesLoaded();

    // Initial check
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled, imagesLoaded]);

  return (
    <div className="apropos-background">
      <div className="container">
        <div className="apropos-container">
          <h1>À Propos</h1>
          <section className="about-section">
            <p className='subtitle-section'>
              Bienvenue chez Edufin, votre partenaire de confiance pour maîtriser l'art de l'investissement tout en restant
              connecté aux dernières tendances financières.
            </p>
            <div className="value-images-section">
              <div className="value-card">
                <div className="image-container">
                  <img src={nosValeur1} alt="Excellence" className="value-image" />
                </div>
                <div className="text-container">
                  <h3 className="image-title">Excellence</h3>
                  <p className="image-subtitle">Une priorité pour nous de vous offrir la meilleure des qualités</p>
                </div>
              </div>
              <div className="value-card">
                <div className="image-container">
                  <img src={nosValeur2} alt="Accessibilité" className="value-image" />
                </div>
                <div className="text-container">
                  <h3 className="image-title">Accessibilité</h3>
                  <p className="image-subtitle">Démocratisons la finance ensemble et pour tous</p>
                </div>
              </div>
              <div className="value-card">
                <div className="image-container">
                  <img src={nosValeur3} alt="Communauté" className="value-image" />
                </div>
                <div className="text-container">
                  <h3 className="image-title">Communauté</h3>
                  <p className="image-subtitle">Rejoignez une communauté aussi passionnée que vous</p>
                </div>
              </div>
              <div className="value-card">
                <div className="image-container">
                  <img src={nosValeur4} alt="Transparence" className="value-image" />
                </div>
                <div className="text-container">
                  <h3 className="image-title">Transparence</h3>
                  <p className="image-subtitle">Faisons-nous confiance et évoluons ensemble</p>
                </div>
              </div>
            </div>
          </section>

          <section className="founders-section">
            <h2>Notre Equipe</h2>
            <p className='founder-subtitle-section'>
              Nos fondateurs, trois experts passionnés, ont uni leurs forces pour créer Edufin, une plateforme où 
              l'apprentissage rencontre l'information.
            </p>
            <div className="founders">
              <div className="founder">
                <div className="founder-img-container">
                  <img className="founder-img" src={HugoBoulet} alt="Hugo Boulet" />
                </div>
                <div className="founder-info">
                  <h3>Hugo Boulet</h3>
                  <h4>CEO | Passionné en Bourse</h4>
                  <p className='founder-subtitle'>Ancien sportif de haut niveau, a transposé sa rigueur du terrain à la bourse, obtenant des certifications 
                  et accumulant une solide expérience en finance de marché.</p>
                  <div className="founder-certifications">
                    <h4>Certifications :</h4>
                    <ul>
                      <li className='certification'>Bloomberg Market Concepts</li>
                      <li className='certification'>Bloomberg Finance Fundamentals</li>
                      <li className='certification'>Certification AMF</li>
                      <li className='certification'>JP Morgan Sales & Trading Virtual Experience</li>
                    </ul>
                  </div>
                  <div className="founder-social-links">
                    <a href="https://www.linkedin.com/in/hugo-boulet/" className="founder-social-link" target="_blank" rel="noopener noreferrer">
                      <img src="linkedin-1-svgrepo-com.svg" alt="LinkedIn" className="founders-social-icons" />
                    </a>
                    <a href="https://www.instagram.com/hugoo.bltt/" className="founder-social-link" target="_blank" rel="noopener noreferrer">
                      <img src="instagram-1-svgrepo-com.svg" alt="Instagram" className="founders-social-icons" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="founder">
                <div className="founder-img-container">
                  <img className="founder-img" src={BaptisteFerahian} alt="Baptiste Ferahian" />
                </div>
                <div className="founder-info">
                  <h3>Baptiste Ferahian</h3>
                  <h4>Co-Founder | Passionné en Crypto</h4>
                  <p className='founder-subtitle'>Autodidacte aguerri du Web3, excelle dans le domaine des cryptomonnaies et oriente ses compétences 
                  vers l'avenir financier.</p>
                  <div className="founder-certifications">
                    <h4>Certifications :</h4>
                    <ul>
                      <li className='certification'>Binance Blockchain For Everyone</li>
                      <li className='certification'>INC Efficiently Managing Your Saving & Investments</li>
                    </ul>
                  </div>
                  <div className="founder-social-links">
                    <a href="https://www.linkedin.com/in/baptiste-ferahian-6184111bb/" className="founder-social-link" target="_blank" rel="noopener noreferrer">
                      <img src="linkedin-1-svgrepo-com.svg" alt="LinkedIn" className="founders-social-icons" />
                    </a>
                    <a href="https://www.instagram.com/baptiste.fhn/" className="founder-social-link" target="_blank" rel="noopener noreferrer">
                      <img src="instagram-1-svgrepo-com.svg" alt="Instagram" className="founders-social-icons" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="founder">
                <div className="founder-img-container">
                  <img className="founder-img" src={AdrienMoncet} alt="Adrien Moncet" />
                </div>
                <div className="founder-info">
                  <h3>Adrien Moncet</h3>
                  <h4>CTO | Passionné en Informatique</h4>
                  <p className='founder-subtitle'>Etudiant en ingénierie informatique et sportif de haut niveau, a créé une plateforme intuitive, rendant les outils financiers 
                  avancés accessibles à tous.</p>
                  <div className="founder-certifications">
                    <h4>Certifications :</h4>
                    <ul>
                      <li className='certification'>Bachelor of Digital & Numerical Engineering</li>
                      <li className='certification'>Certifications CISCO Cybersecurity</li>
                      <li className='certification'>Certifications Microsoft Azure</li>
                      <li className='certification'>Certifications IBM</li>
                    </ul>
                  </div>
                  <div className="founder-social-links">
                    <a href="https://www.linkedin.com/in/adrien-moncet-95aaaa17b/" className="founder-social-link" target="_blank" rel="noopener noreferrer">
                      <img src="linkedin-1-svgrepo-com.svg" alt="LinkedIn" className="founders-social-icons" />
                    </a>
                    <a href="https://www.instagram.com/a.moncet/" className="founder-social-link" target="_blank" rel="noopener noreferrer">
                      <img src="instagram-1-svgrepo-com.svg" alt="Instagram" className="founders-social-icons" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default APropos;