import React, { useEffect, useRef } from 'react';
import './NewsSection.css';
import { Link } from 'react-router-dom';
import { articleData } from '../Articles/ArticlePage'; // Importez les articles

// Convertir les données des articles en un tableau et trier par date
const articles = Object.keys(articleData).map(key => ({
  id: key,
  title: articleData[key].title,
  date: new Date(articleData[key].date), // Convertir la date en objet Date
  author: articleData[key].author,
  imageUrl: articleData[key].imageUrl1, // Assurez-vous d'utiliser la bonne clé pour l'image
  tag: articleData[key].tag, // Ajouter le tag
  link: `/articles/${key}`
})).sort((a, b) => b.date - a.date); // Trier les articles par date (du plus récent au plus ancien)

const NewsSection = () => {
  const newsItemsRef = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const options = {
      root: isMobile ? null : null, // Change `null` to `newsItemsRef.current` if needed for specific scrolling container
      rootMargin: '0px',
      threshold: isMobile ? Array.from({ length: 101 }, (_, i) => i / 100) : 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const ratio = entry.intersectionRatio; // Proportion de visibilité
          if (isMobile) {
            entry.target.style.opacity = ratio;
          } else {
            entry.target.classList.add('visible');
          }
        }
      });
    }, options);

    const newsCards = document.querySelectorAll('.news-card');
    newsCards.forEach((card) => observer.observe(card));

    const button = document.querySelector('.NewsSecondaryButton');
    if (button) {
      observer.observe(button);
    }

    return () => {
      newsCards.forEach((card) => observer.unobserve(card));
      if (button) {
        observer.unobserve(button);
      }
    };
  }, []);

  const latestArticles = articles.slice(0, 3); // Obtenir les 3 derniers articles

  return (
    <section className="news" ref={newsItemsRef}>
      <div>
        <h1 className="cta-newsTitle">Top News</h1>
      </div>
      <div className="news-items">
        {latestArticles.map((article, index) => (
          <Link key={index} to={article.link} className="news-card no-decoration">
            <img src={article.imageUrl} alt={article.title} className="image" />
            <p className="top-news-meta">{article.date.toLocaleDateString()} | {article.author}</p>
            <h3 className="newsTitle">{article.title}</h3>
          </Link>
        ))}
      </div>
      <div className="NewsButtonContainer">
        <Link to="/searchDropdown">
          <button className="NewsSecondaryButton">Voir plus d'articles</button>
        </Link>
      </div>
    </section>
  );
}

export default NewsSection;