import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { articleData } from '../Articles/ArticlePage';
import ArticleCard from '../Articles/ArticleCard';
import NewsCarousel from './NewsCarouselBourse'; // Adaptez le chemin vers NewsCarousel
import './SearchResults.css';

// Convertir les données des articles en un tableau et filtrer par tag "crypto"
const articles = Object.keys(articleData).map(key => ({
  id: key,
  title: articleData[key].title,
  date: new Date(articleData[key].date),
  author: articleData[key].author,
  content1: articleData[key].content1,
  imageUrl: articleData[key].imageUrl1,
  tag: articleData[key].tag,
  link: `/articles/${key}`
})).filter(article => article.tag === 'bourse');

// Trier les articles par date
articles.sort((a, b) => b.date - a.date);

const SearchDropdownBourse = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 5;
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query') || '';
    setSearchTerm(DOMPurify.sanitize(query));

    const filteredResults = articles.filter(article =>
      DOMPurify.sanitize(article.title.toLowerCase()).includes(query.toLowerCase()) ||
      DOMPurify.sanitize(article.content1.toLowerCase()).includes(query.toLowerCase())
    );
    setFilteredArticles(filteredResults);
    setCurrentPage(1);
  }, [location.search]);

  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSearch = (event) => {
    const term = DOMPurify.sanitize(event.target.value.toLowerCase());
    setSearchTerm(term);
    const filteredResults = articles.filter(article =>
      DOMPurify.sanitize(article.title.toLowerCase()).includes(term) ||
      DOMPurify.sanitize(article.content1.toLowerCase()).includes(term)
    );
    setFilteredArticles(filteredResults);
    setCurrentPage(1);
  };

  return (
    <>
      {currentPage === 1 && (
        <div className="NewsCarousel-page">
          <NewsCarousel />
        </div>
      )}
      <div className="background">
        {currentPage === 1 ? (
          <div className="search-dropdown">
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
        ) : (
          <div className="divider"></div> // Utiliser la classe CSS personnalisée
        )}
        <div className="search-results">
          {currentArticles.length > 0 ? (
            currentArticles.map(article => (
              <ArticleCard
                key={article.id}
                title={DOMPurify.sanitize(article.title)}
                date={article.date instanceof Date ? article.date.toLocaleDateString() : article.date}
                author={DOMPurify.sanitize(article.author)}
                imageUrl={article.imageUrl}
                content1={DOMPurify.sanitize(article.content1)}
                link={article.link}
              />
            ))
          ) : (
            <p className="error">Aucun résultat trouvé</p>
          )}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchDropdownBourse;