import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Lerning.css';
import AcademieImage from '../assets/Académie_image.png';

const Lerning = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/académie');
    window.scrollTo(0, 0); // Fait défiler la page vers le haut
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: isMobile ? 0.7 : 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, options);

    const platformImage = document.querySelector('.ctaSection');
    if (platformImage) {
      observer.observe(platformImage);
    }

    return () => {
      if (platformImage) {
        observer.unobserve(platformImage);
      }
    };
  }, []);

  return (
    <section className="ctaSection">
      <div className="ctaContainer">
        <h1 className="ctaTitle">Rejoignez l'Académie Edufin !</h1>
        <p className="ctaSubtitle">
          L'Académie Edufin offre une variété de cours et de ressources pour vous aider à maîtriser les concepts financiers. Que vous soyez débutant ou expert, nos cours sont conçus pour vous fournir les connaissances et les compétences nécessaires pour réussir dans le monde de la finance.
        </p>
        <button className="primaryButton" onClick={handleRedirect} aria-label="Aller à l'Académie">
          Aller à l'Académie
        </button>
      </div>
      <div className="ctaImageContainer">
        <img src={AcademieImage} alt="Académie Edufin" className="ctaImage" />
      </div>
    </section>
  );
};

export default Lerning;