import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Lerning.css';
import AcademieImage from '../assets/Developpez-Vos-Stratégies.png';

const Invest = () => {
  const navigate = useNavigate();
  
    const handleRedirect = () => {
      navigate('/actualités');
      window.scrollTo(0, 0); // Fait défiler la page vers le haut
    };
  
    useEffect(() => {
      const isMobile = window.innerWidth <= 768;
  
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: isMobile ? 0.7 : 0.7,
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      }, options);
  
      const platformImage = document.querySelector('.cta-investSection');
      if (platformImage) {
        observer.observe(platformImage);
      }
  
      return () => {
        if (platformImage) {
          observer.unobserve(platformImage);
        }
      };
    }, []);

  return (
    <section className="cta-investSection">
      <div className="cta-investContainer">
        <h1 className="ctaTitle">Developpez Vos Stratégies d'Investissement</h1>
        <p className="ctaSubtitle">
          Découvrez nos stratégies d'investissement et apprenez à gérer votre portefeuille pour maximiser vos rendements. Que vous soyez un investisseur débutant ou expérimenté, nos ressources et conseils vous aideront à prendre des décisions éclairées et à atteindre vos objectifs financiers.
        </p>
        <button className="primaryButton" onClick={handleRedirect}>
          Commencez à investir
        </button>
      </div>
      <div className="cta-investImageContainer">
        <img src={AcademieImage} alt="Académie Edufin" className="cta-investImage" />
      </div>
    </section>
  );
};

export default Invest;