import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Crypto.css'; // Assurez-vous de créer ce fichier CSS pour le style
import { articleData } from '../Articles/ArticlePage'; // Importez les articles

const Crypto = () => {
  const [bigNewsArticle, setBigNewsArticle] = useState(null);
  const [otherArticles, setOtherArticles] = useState([]);

  useEffect(() => {
    // Convertir les données des articles en un tableau et trier par date
    const articles = Object.keys(articleData).map(key => ({
      id: key,
      title: articleData[key].title,
      date: new Date(articleData[key].date), // Convertir la date en objet Date
      author: articleData[key].author,
      imageUrl: articleData[key].imageUrl1, // Assurez-vous d'utiliser la bonne clé pour l'image
      tag: articleData[key].tag, // Ajouter le tag
      content1: articleData[key].content1, // Ajouter le contenu
      link: `/articles/${key}`
    })).sort((a, b) => b.date - a.date); // Trier du plus récent au plus ancien

    const cryptoArticles = articles.filter(article => article.tag === 'crypto');
    const firstArticle = cryptoArticles[0];
    const nextFour = cryptoArticles.slice(1, 5);

    setBigNewsArticle(firstArticle);
    setOtherArticles(nextFour);
  }, []);

  const removeStrongTags = (text) => {
    return text.replace(/<\/?strong>/g, '');
  };

  const getFirstSentences = (text) => {
    if (!text) return ''; // Vérifiez si le texte est défini
    const cleanText = removeStrongTags(text);
    const matches = cleanText.match(/[^.!?]*[.!?]/g);
    return matches ? matches.slice(0, 2).join(' ') : cleanText;
  };

  return (
    <div className="page-container">
      <div className="crypto-header">
        <h1>Actualités Crypto</h1>
        <p>Toutes les dernières nouvelles sur les cryptomonnaies</p>
      </div>
      <div className="crypto-container">
        <div className="left-block">
          {bigNewsArticle ? (
            <div className="big-news-card-container">
              <Link to={bigNewsArticle.link} className="big-news-card">
                <div className="image-container">
                  <img src={bigNewsArticle.imageUrl} alt={bigNewsArticle.title} className="image" />
                </div>
                <div className="crypto-newsContent">
                  <p className="crypto-newsMeta">
                    {new Date(bigNewsArticle.date).toLocaleDateString()} | {bigNewsArticle.author}
                  </p>
                  <h3 className="crypto-newsTitle">{bigNewsArticle.title}</h3>
                  <p className="crypto-newsExcerpt justify-text">{getFirstSentences(bigNewsArticle.content1)}</p>
                </div>
              </Link>
            </div>
          ) : (
            <p>Aucun article disponible.</p>
          )}
          
        </div>
        <div className="right-block">
          {otherArticles.map(article => (
            <div key={article.id} className="small-news-card-container">
              <Link to={article.link} className="small-news-card">
                <div className="image-container">
                  <img src={article.imageUrl} alt={article.title} className="image" />
                </div>
                <div className="crypto-newsContent">
                  <p className="crypto-newsMeta">
                    {new Date(article.date).toLocaleDateString()} | {article.author}
                  </p>
                  <h4 className="crypto-newsTitle">{article.title}</h4>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="see-more-button-container">
        <Link to="/searchDropdownCrypto">
          <button className="see-more-button">Voir plus d'articles</button>
        </Link>
      </div>
    </div>
  );
};

export default Crypto;