import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./ArticleTemplate.css";
import InstagramLogo from '../assets/Instagram.png'; // Importez le logo Instagram
import LinkedInLogo from '../assets/Linkedin.png'; // Importez le logo LinkedIn
import TiktokLogo from '../assets/Tiktok.png'; // Importez le logo TikTok
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { FaShareAlt } from 'react-icons/fa'; // Importez l'icône de partage
import { articleData } from '../Articles/ArticlePage'; // Importez les articles

const ArticleTemplate = ({ title, date, author, tag, content1, imageUrl1, credit1, content2, imageUrl2, credit2 }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [latestArticles, setLatestArticles] = useState([]);
  const sidebarRef = useRef(null);
  const articleRef = useRef(null);
  const location = useLocation();

  // Fonction utilitaire pour supprimer les balises HTML
  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  // Fonction utilitaire pour extraire la première phrase
  const getFirstSentence = (str) => {
    if (!str) return '';
    const strippedStr = stripHtmlTags(str);
    const firstSentence = strippedStr.match(/[^.!?]*[.!?]/);
    return firstSentence ? firstSentence[0] : strippedStr;
  };

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const article = articleRef.current;
      if (!sidebar || !article) return; // Ajoutez cette vérification
      const articleBottom = article.getBoundingClientRect().bottom;
      const sidebarHeight = sidebar.offsetHeight;

      if (articleBottom <= sidebarHeight) {
        sidebar.style.position = 'absolute';
        sidebar.style.top = `${articleBottom - sidebarHeight}px`;
      } else {
        sidebar.style.position = 'sticky';
        sidebar.style.top = '120px';
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Charger les articles et les trier par date
    const articles = Object.keys(articleData).map(key => ({
      id: key,
      title: articleData[key].title,
      date: new Date(articleData[key].date), // Convertir la date en objet Date
      author: articleData[key].author,
      imageUrl: articleData[key].imageUrl1, // Assurez-vous d'utiliser la bonne clé pour l'image
      tag: articleData[key].tag, // Ajouter le tag
      link: `/articles/${key}`
    })).sort((a, b) => b.date - a.date); // Trier les articles par date (du plus récent au plus ancien)

    setLatestArticles(articles.slice(0, 3)); // Obtenir les 3 derniers articles
  }, []);

  const shareUrl = `${window.location.origin}${location.pathname}`; // URL de la page actuelle

  return (
    <div className="article-template-background">
      <Helmet>
        {/* Balises Open Graph */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={getFirstSentence(content1)} />
        <meta property="og:image" content={imageUrl1} />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="article" />

        {/* Balises Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={getFirstSentence(content1)} />
        <meta name="twitter:image" content={imageUrl1} />
        <meta name="twitter:url" content={shareUrl} />
      </Helmet>
      <div className="article-template-wrapper">
        <div className="article-template-container">
          <header className="article-template-header">
            <h1 className="article-template-title">{title}</h1>
            <div className="article-template-meta">
              <span className="article-template-date">{date}</span>
              <span className="article-template-separator">|</span>
              <span className="article-template-author">Par {author}</span>
              <span className="article-template-separator">|</span>
              <span className="article-template-tag">{tag}</span> {/* Affichage du tag */}
            </div>
            <div className="article-template-content" dangerouslySetInnerHTML={{ __html: content1 }}></div>
          </header>
          <div className="article-template-body">
            {imageUrl1 && (
              <>
                <img src={imageUrl1} alt={title} className="article-template-image" />
                <p className="image-template-credit">{credit1}</p>
              </>
            )}
            <div className="article-template-content" dangerouslySetInnerHTML={{ __html: content2 }}></div>
            {imageUrl2 && (
              <>
                <img src={imageUrl2} alt={title} className="article-template-image" />
                <p className="image-template-credit">{credit2}</p>
              </>
            )}
          </div>
          {/* Section des réseaux sociaux déplacée ici */}
          <aside className="social-network">
            <h2 className="social-network-title">Suivez-nous et partagez l'article</h2>
            <ul className="social-network-list">
              <li className="social-network-item">
                <a href="https://www.instagram.com/edufin.off/" className="social-network-link" target="_blank" rel="noopener noreferrer">
                  <img src={InstagramLogo} alt="Instagram" className="social-network-logo" />
                </a>
              </li>
              <li className="social-network-item">
                <a href="https://www.linkedin.com/company/edufinoff/posts/?feedView=all" className="social-network-link" target="_blank" rel="noopener noreferrer">
                  <img src={LinkedInLogo} alt="LinkedIn" className="social-network-logo" />
                </a>
              </li>
              <li className="social-network-item">
                <a href="https://www.tiktok.com/@edufin.off?is_from_webapp=1&sender_device=pc" className="social-network-link" target="_blank" rel="noopener noreferrer">
                  <img src={TiktokLogo} alt="TikTok" className="social-network-logo" />
                </a>
              </li>
              <li className="social-network-item">
                <button className="social-network-link" onClick={() => setShowShareOptions(!showShareOptions)}>
                  <FaShareAlt className="social-network-logo"/>
                  <span className={`navbar__arrow ${showShareOptions ? 'navbar__arrow--up' : ''}`}>&#9660;</span>
                </button>
                {showShareOptions && (
                  <div className="share-options">
                    <FacebookShareButton url={shareUrl} className="share-option">
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} className="share-option">
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl} className="share-option">
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <WhatsappShareButton url={shareUrl} className="share-option">
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </div>
                )}
              </li>
            </ul>
          </aside>
        </div>
        <div className="sidebar" ref={sidebarRef}>
          <aside className="most-read-container">
            <h2 className="most-read-title">Top News</h2>
            <ul className="most-read-list">
              {latestArticles.map((article, index) => (
                <li key={index} className="most-read-item">
                  <Link to={article.link}>
                    <div className="most-read-content">
                      <img src={article.imageUrl} alt={article.title} className="most-read-image" />
                      <p className="most-read-link">{article.title}</p>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ArticleTemplate;