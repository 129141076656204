import React, { useEffect, useRef, useState } from 'react';
import Counter from './Counter';
import './Statistics.css'; // Assurez-vous d'importer le fichier CSS

function Statistics() {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleCounters, setVisibleCounters] = useState([false, false, false]);
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Ajustez le seuil pour qu'au moins 50% de la section soit visible
    );

    const currentRef = statsRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      visibleCounters.forEach((_, index) => {
        setTimeout(() => {
          setVisibleCounters((prev) => {
            const newCounters = [...prev];
            newCounters[index] = true;
            return newCounters;
          });
        }, index * 1000); // Délai de 500ms entre chaque compteur
      });
    }
  }, [isVisible, visibleCounters]);

  return (
    <section ref={statsRef} className="stats">
      <h2 className="stats-title">Nos Statistiques</h2>
      <div className="stats-items">
        <div className={`statItem ${visibleCounters[0] ? 'visible' : ''}`}>
          {visibleCounters[0] && <Counter end="7" duration="1" prefix="+" suffix="h" className="counter" start={visibleCounters[0]} />}
          <p>de formation</p>
        </div>
        <div className={`statItem ${visibleCounters[1] ? 'visible' : ''}`}>
          {visibleCounters[1] && <Counter end="7" duration="1" prefix="+" className="counter" start={visibleCounters[1]} />}
          <p>Stratégies clés en main</p>
        </div>
        <div className={`statItem ${visibleCounters[2] ? 'visible' : ''}`}>
          {visibleCounters[2] && <Counter end="30" duration="1" prefix="+" suffix="%" className="counter" start={visibleCounters[2]} />}
          <p>de performance de portefeuilles</p>
        </div>
      </div>
    </section>
  );
}

export default Statistics;