import React, { useEffect } from 'react';
import '../pages/Académie.css'; // Importez le fichier CSS
import HeroSection from '../components/HeroSection2';
import Statistics2 from '../components/Statistics2';
import VisuelIphone from '../assets/Académie-phone-image.png'; // Importez l'image
import SuivisPersonnalise from '../assets/Suivi-personnalisé.png'; // Importez l'image
import PortefeuillePrive from '../assets/Portefeuille-privé.png'; // Importez l'image
import ModulesEtBonus from '../assets/Modules-et-bonus.png'; // Importez l'image

function Académie() {
  useEffect(() => {
    const handleScroll = () => {
      const errorItems = document.querySelectorAll('.errorItem');
      errorItems.forEach(item => {
        const rect = item.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight * 1.1) {
          item.classList.add('visible');
        } 
      });

      const advantageCards = document.querySelectorAll('.advantage-card');
      advantageCards.forEach((card, index) => {
        const rect = card.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight * 1.2) {
          setTimeout(() => {
            card.classList.add('visible');
          }, index * 200); // Déclenche l'animation avec un décalage de 200ms entre chaque carte
        }
      });

      const platformImage = document.querySelector('.platformSection');
      if (platformImage) {
        const rect = platformImage.getBoundingClientRect();
        const isMobile = window.innerWidth <= 768;
        if (rect.top >= 0 && rect.bottom <= window.innerHeight * (isMobile ? 1.2 : 1.2)) {
          platformImage.classList.add('visible');
        }
      }

      const button = document.querySelector('.Académie-secondaryButton');
      if (button) {
        const rect = button.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          button.classList.add('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Initial check
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="page">
      <div className="content">
        <HeroSection />
        <div className="académie-container">
          {/* Section principale avec le titre et le CTA */}
          <header className="header">
            <h1 className="Académie-sectionTitle">Formez-vous à l'investissement grâce à une formation simple, complète et accessible</h1>
            <p className="subtitle">Formation complète pour tous les niveaux, à un prix imbattable !</p>
          </header>
          <Statistics2 />

          {/* Section: Plateforme (Dashboard) */}
          <section className="platformSection">
            <div className="platformContainer">
              <h1 className="platformTitle">Simplifiez-vous la vie avec Edufin !</h1>
              <p className="platformSubtitle">
              Découvrez comment notre académie peut transformer votre approche de l'investissement. Avec des cours interactifs, des outils puissants et un soutien personnalisé, vous serez équipé pour atteindre vos objectifs financiers plus rapidement et plus efficacement. Rejoignez-nous et commencez votre parcours vers la réussite dès aujourd'hui !              </p>
            </div>
            <div className="platformImageContainer">
              <img src={VisuelIphone} alt="Académie Edufin" className="platformImage" />
            </div>
          </section>

          {/* Section: Les 3 plus grosses erreurs */}
          <section className="errorsSection">
            <h2 className="ErrorSectionTitle">Les 3 plus grosses erreurs que les gens font lorsqu’ils investissent</h2>
            <div className="errorsContainer">
              <div className="errorItem">
                <img src="/assets/Erreur1.png" alt="Erreur 1" className="errorImage" />
                <div className="errorContent">
                  <h3 className="errorTitle">1. Manque d'informations claires</h3>
                  <p className="errorText">
                    Les débutants sont souvent confrontés à une abondance d'informations complexes et contradictoires, ce qui rend difficile la prise de décision éclairée. Sans une compréhension claire des concepts de base, ils risquent de faire des choix hasardeux.
                  </p>
                </div>
              </div>
              <div className="errorItem error2">
                <img src="/assets/Erreur2.png" alt="Erreur 2" className="errorImage" />
                <div className="errorContent">
                  <h3 className="errorTitle">2. Manque de suivi</h3>
                  <p className="errorText">
                    Sans un système de suivi efficace, il est difficile de mesurer les progrès ou de détecter les erreurs à temps. Cela peut conduire à une mauvaise gestion du portefeuille et à des pertes financières.
                  </p>
                </div>
              </div>
              <div className="errorItem">
                <img src="/assets/Erreur3.png" alt="Erreur 3" className="errorImage" />
                <div className="errorContent">
                  <h3 className="errorTitle">3. Manque de compréhension</h3>
                  <p className="errorText">
                    Beaucoup d'investisseurs débutants manquent de connaissances sur les produits financiers qu'ils achètent. Cela peut entraîner des choix inappropriés, mal alignés avec leurs objectifs financiers, et accroître le risque global de leur portefeuille.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Section: Question & Accroche */}
          <section className="questionSection">
            <h2 className="questionTitle">Quels seront vos avantages en rejoignant l’académie Edufin ?</h2>
            <p className="questionText">
              Avec une plateforme dédiée pour vous, vous retrouverez les modules et bonus de formation avec un suivi complet de votre apprentissage, le suivi de vos investissements et performances, ainsi que notre portefeuille privé en totale transparence, sans compter un assistant dédié pour vous accompagner.
            </p>
            <div className="advantages-container">
              <div className="advantage-card">
                <img src={SuivisPersonnalise} alt="Suivi personnalisé" className="advantage-image" />
                <h3>Suivi personnalisé</h3>
                <p>Profitez d'un suivi personnalisé pour optimiser votre apprentissage et vos investissements.</p>
              </div>
              <div className="advantage-card">
                <img src={PortefeuillePrive} alt="Portefeuille privé" className="advantage-image" />
                <h3>Portefeuille privé</h3>
                <p>Accédez à notre portefeuille privé et suivez nos investissements en toute transparence.</p>
              </div>
              <div className="advantage-card">
                <img src={ModulesEtBonus} alt="Modules et bonus" className="advantage-image" />
                <h3>Modules et bonus</h3>
                <p>Accédez à des modules de formation et des bonus exclusifs pour enrichir vos connaissances.</p>
              </div>
            </div>
            <a href="https://edufin.podia.com/" target="_blank" rel="noopener noreferrer">
              <button className="Académie-secondaryButton">Obtenir un accès</button>
            </a>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Académie;