import React, { useEffect, useRef, useState } from 'react';
import Counter from './Counter';
import Counter2 from './Counter2';
import './Statistics.css'; // Assurez-vous d'importer le fichier CSS

function Statistics2() {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleCounters, setVisibleCounters] = useState([false, false, false]);
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Ajustez le seuil pour qu'au moins 50% de la section soit visible
    );

    const currentRef = statsRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      visibleCounters.forEach((_, index) => {
        setTimeout(() => {
          setVisibleCounters((prev) => {
            const newCounters = [...prev];
            newCounters[index] = true;
            return newCounters;
          });
        }, index * 1000); // Délai de 500ms entre chaque compteur
      });
    }
  }, [isVisible, visibleCounters]);

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <section ref={statsRef} className="stats-académie">
      <div className={`statItem ${visibleCounters[0] ? 'visible' : ''}`}>
        {visibleCounters[0] && <Counter2 end={19.99} duration="1" suffix="€" className="counter" start={visibleCounters[0]} formatNumber={formatNumber} />}
        <p>à l'unité sans engagement</p>
      </div>
      <div className={`statItem ${visibleCounters[1] ? 'visible' : ''}`}>
        {visibleCounters[1] && <Counter2 end={139.90} duration="1" suffix="€" className="counter" start={visibleCounters[1]} formatNumber={formatNumber} />}
        <p>comptant (possibilité de 3x sans frais)</p>
      </div>
      <div className={`statItem ${visibleCounters[2] ? 'visible' : ''}`}>
        {visibleCounters[2] && <Counter end={30} duration="1" prefix="+" suffix="%" className="counter" start={visibleCounters[2]} formatNumber={formatNumber} />}
        <p>de performance de portefeuilles</p>
      </div>
    </section>
  );
}

export default Statistics2;