import React from 'react';
import HeroSection from '../components/HeroSection';
import Statistics from '../components/Statistics';
import NewsSection from '../components/NewsSection';
import Lerning from '../components/Lerning';
import Invest from '../components/Invest';
import './Home.css';

function Home() {
  return (
    <div className="page">
      <div className="home-content">
        <HeroSection />
        <Statistics />
        <Lerning />
        <NewsSection />
        <Invest />
      </div>
    </div>
  );
}

export default Home;