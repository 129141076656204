import React from 'react';
import './HeroSection2.css';

const HeroSection = () => {

  return (
    <section className="académie-hero">
      <div className="académie-textContainer">
        <h1 className="académie-heading">Bienvenue à l'Académie !</h1>
        <p className="académie-paragraph">
          Inscrivez-vous <strong style={styles.bold}>dès maintenant</strong> pour accéder à des cours exclusifs et à des ressources pédagogiques de qualité. Que vous soyez débutant ou expert, l'Académie Edufin vous accompagne dans votre apprentissage financier.
        </p>
        <div className="académie-buttonContainer">
          <a href="https://edufin.podia.com/" className="académie-primaryButton" target="_blank" rel="noopener noreferrer">
            Inscrivez-vous
          </a>
        </div>
      </div>
      <div className="visual">
        <iframe 
          className="iframe" 
          src="https://www.youtube.com/embed/Bsw_XtkJ0ig?si=Gid5hyrxTUtn7jRe"  
          title="YouTube video player" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

const styles = {

  bold: {
    fontWeight: 'bold',
  },
};

export default HeroSection;
